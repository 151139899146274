import React, { useState } from 'react';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import { v4 as uuid } from 'uuid';
import Page from '@components/page';
import VideoPlayer from '@components/video-player';
import IntroModal from '@components/intro-modal';
import Assets from '@assets';
import DATA from '@data';

const introTitle = 'Intro';
const introSubTitle = 'Welcome to Gene Pilot!';

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Intro = () => {
  const [ showIntroModal, setShowIntroModal ] = useState(true);
  const [ autoplay, setAutoplay ] = useState(false);
  const [ rightNavigationActive, setRightNavigationActive ] = useState(false);

  const handleOnRightNavigationPress = () => {
    window.localStorage.setItem('session-id', uuid());

    const completedSteps = {
      intro: true,
    };
    window.localStorage.setItem('completed-steps', JSON.stringify(completedSteps));

    navigate('/menu?autoplay=1');
  };

  const handleVideoPlayerOnFinish = () => {
    setRightNavigationActive(true);
    ReactGA.event({
      category: 'INTRO',
      action: `User viewed "Intro's" video`,
    });
  };

  const handleIntroModalOnClose = () => {
    setShowIntroModal(false);
    setAutoplay(!iOS());
  };

  return (
    <React.Fragment>
      <Page
        title={introTitle}
        subTitle={introSubTitle}
        icon={Assets.images.PageIconIntro}
        menuDisabled={true}
        withNavigation
        withFloatNavigation
        leftNavigation={false}
        rightNavigationActive={rightNavigationActive}
        rightNavigationText="Next"
        onRightNavigationPress={handleOnRightNavigationPress}
      >
        <VideoPlayer
          file={DATA.INTRO.file}
          transcript={DATA.INTRO.transcript}
          onFinish={handleVideoPlayerOnFinish}
          thumbnail={DATA.INTRO.thumbnail}
          autoplay={autoplay}
        />
      </Page>
      {showIntroModal ? (
        <IntroModal
          icon={Assets.images.PageIntroIconIntro}
          needsConfirmation
          confirmationMessage="Begin Journey"
          onClose={handleIntroModalOnClose}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Intro;
